import axios from 'axios';
import { logout } from './commonUtils';

// Create a new Axios instance with default configuration
const API_URL = process.env.REACT_APP_API_URL;
const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to set the Authorization header with the token from localStorage
instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('token');
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Common function to make HTTP requests using the global Axios instance
async function makeRequest(method, url, body = null, headers = {}, onProgress = null) {
  try {
    const config = {
      method,
      url,
      data: body,
      headers: { ...headers },
      onUploadProgress: onProgress,
    };

    const response = await instance(config);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle errors
    if (error.response.status === 401) {
      logout();
    }
    return error?.response?.data;
  }
}

export const loginApi = (body) => {
  return makeRequest('post', 'admin/login', body);
};

export const updatePasswordApi = (body) => {
  return makeRequest('put', 'admin/update-password', body);
}

export const getAllDietitianApi = (search, searchEmp, page, limit) => {
  return makeRequest(
    'get',
    `admin/get-dietitian?search=${search}&searchEmp=${searchEmp}&page=${page}&limit=${limit}`,
    {}
  );
};

export const getAllDietitianNamesApi = () => {
  return makeRequest('get', `admin/get-dietitian-names`, {});
};

export const getDietitianByIdApi = (id) => {
  return makeRequest('get', `admin/get-dietitian/${id}`, {});
};

export const getAllInsuranceApi = (search, page, limit) => {
  return makeRequest('get', `admin/get-insurance?search=${search}&page=${page}&limit=${limit}`, {});
};

export const editDietitian = (id, body) => {
  return makeRequest('put', `admin/edit-dietitian/${id}`, body);
};

export const editDietitianAssignedInsurance = (id, body) => {
  return makeRequest('put', `/admin/edit-insurance-assigned-dietitian/${id}`, body);
};

export const addEditReviewApi = (body) => {
  return makeRequest('post', `/admin/add-edit-review`, body);
};

export const deleteReviewApi = (id, body) => {
  return makeRequest('delete', `/admin/delete-review/${id}`, body);
};

export const deleteDietitianApi = (id) => {
  return makeRequest('delete', `/admin/delete-dietitian/${id}`, {});
};

export const addDietitianApi = (body) => {
  return makeRequest('post', `/admin/add-dietitian`, body);
};

export const getPayrollListSearchApi = (body) => {
  return makeRequest('post', `/admin/get-search-payroll`, body);
};

export const getGraphDataForKPIS = (body) => {
  return makeRequest('post', `/admin/get-dietitians-kpis`, body);
};

export const getWeeklyAverageApi = (body) => {
  return makeRequest('post', `/admin/get-weekly-average`, body);
};

export const getWeeklyAveragesWithDietitianNameApi = (body) => {
  return makeRequest('post', `/admin/get-weekly-average-with-dietitian-name`, body);
}

export const getWeeklyAveragesWithDietitianNameNewApi = (body) => {
  return makeRequest('post', `/admin/get-weekly-average-with-dietitian-name-new`, body);
}

export const getAppointmentTypesApi = () => {
  return makeRequest('get', `/admin/get-appt-types`);
}

export const updateWeeklyAverageCountApi = (body) => {
  return makeRequest('post', `/admin/update-weekly-average-count`, body);
}

export const addSubSessionApi = (body) => {
  return makeRequest('post', `/admin/add-sub-session`, body);
}

export const addPatientReview = (body) => {
  return makeRequest('post', `/admin/add-patientreview`, body);
};

export const averagefeedback = (body) => {
  return makeRequest('post', `/admin/avg-feedback`, body);
};

export const pdfdownload = (body) => {
  return makeRequest('post', `/admin/pdfdownload`, body);
};

export const kpireportonmail = (body) => {
  return makeRequest('post', `/admin/reportonmail`, body);
};

//get all stage list
export const getAllStates = () => {
  return makeRequest('get', `/master/state-list`);
};

export const getAllCareContinuityPatient = (body) => {
  return makeRequest('post', `/admin/get-care-continuity-kpis`, body);
};

export const getAllCareConsistencyPatient = (body) => {
  return makeRequest('post', `/admin/get-care-consistency-kpis`, body);
};

export const getAllCareCommitmentPatient = (body) => {
  return makeRequest('post', `/admin/get-care-commitment-kpis`, body);
};

export const getAllCareEngagementPatient = (body) => {
  return makeRequest('post', `/admin/get-care-engagement-kpis`, body);
};

//New KPI API
export const getAllCareContinuityApiCall = (body) => {
  return makeRequest('post', `/admin/fetch-care-continuity`, body);
};

export const deleteCareContinuityApi = (body) => {
  return makeRequest('post', `/admin/delete-care-continuity`, body);
}

export const undoCareContinuityApi = (body) => {
  return makeRequest('post', `/admin/undo-care-continuity`, body);
}

export const switchCareContinuityApi = (body) => {
  return makeRequest('put', `/admin/switch-care-continuity`, body);
}

export const getAllCareConsistencyApiCall = (body) => {
  return makeRequest('post', `/admin/fetch-care-consistency`, body);
};

export const deleteCareConsistencyApi = (body) => {
  return makeRequest('post', `/admin/delete-care-consistency`, body);
}

export const undoCareConsistencyApi = (body) => {
  return makeRequest('post', `/admin/undo-care-consistency`, body);
}

export const switchCareConsistencyApi = (body) => {
  return makeRequest('put', `/admin/switch-care-consistency`, body);
}

export const getAllCareCommitmentApiCall = (body) => {
  return makeRequest('post', `/admin/fetch-care-commitment`, body);
};

export const deleteCareCommitmentApi = (body) => {
  return makeRequest('post', `/admin/delete-care-commitment`, body);
}

export const undoCareCommitmentApi = (body) => {
  return makeRequest('post', `/admin/undo-care-commitment`, body);
}

export const switchCareCommitmentApi = (body) => {
  return makeRequest('put', `/admin/switch-care-commitment`, body);
}

export const getAllCareEngagementApiCall = (body) => {
  return makeRequest('post', `/admin/fetch-care-engagement`, body);
};

export const deleteCareEngagementApi = (body) => {
  return makeRequest('post', `/admin/delete-care-engagement`, body);
}

export const undoCareEngagementApi = (body) => {
  return makeRequest('post', `/admin/undo-care-engagement`, body);
}

export const editCareEngagementApi = (body) => {
  return makeRequest('put', `/admin/edit-care-engagement-session-count`, body);
}