import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guard/AuthGuard';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  const userData = localStorage.getItem('user');
  const parsedUserData = JSON.parse(userData);
  const role = parsedUserData?.role || '';
  return useRoutes([
    {
      path: '/',
      element: role === 'dietitian' ? <Navigate to="/carepis" replace /> : <Navigate to="/employees" replace />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/employees',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Dietitian /> },
        { path: ':id', element: <DietitianAddEdit /> },
        { path: 'add', element: <DietitianAddEdit /> },
        { path: 'employee/:id', element: <EmployeeAddEdit /> },
        { path: 'employee/add', element: <EmployeeAddEdit /> },
        // { path: 'payroll', element: <Payroll /> },
      ],
    },
    {
      path: '/payroll',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <Payroll /> }],
    },
    {
      path: '/weekly-averages',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <WeeklyAverage /> }],
    },
    {
      path: '/carepis',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <DietitianKPI /> },
      ],
    },
  ]);
}

// Dashboard
const Dietitian = Loadable(lazy(() => import('../pages/Dietitians')));
const DietitianAddEdit = Loadable(lazy(() => import('../pages/AddEditDietitian')));
const EmployeeAddEdit = Loadable(lazy(() => import('../pages/AddEditEmployee')));
const Payroll = Loadable(lazy(() => import('../pages/Payroll')));
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const WeeklyAverage = Loadable(lazy(() => import('../pages/WeeklyAverage')));
const DietitianKPI = Loadable(lazy(() => import('../pages/DietitianKPI')));
// const NotFound = Loadable(lazy(() => import('../pages/Page404')));
