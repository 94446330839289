import useAuthUser from '../../../hooks/useAuthUser';
import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, useMediaQuery, useTheme } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { logout } from '../../../utils/commonUtils';
import Placeholder from "../../../assets/placeholder.png"
import { Grid } from 'rsuite';
import { updatePasswordApi } from '../../../utils/api';
import toast from 'react-hot-toast';
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { email, full_name, image } = useAuthUser()
  const [open, setOpen] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [password, setPassword] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpen(null);
  }

  const handleUpdatePassword = async () => {
    try {
      const response = await updatePasswordApi({ email: email, password: password });
      if (response.success) {
        toast.success(response.message);
        handleCloseDialog();
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={image || Placeholder} alt="admin" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {full_name || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email || ''}
          </Typography>
        </Box>




        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleOpenDialog} sx={{ m: 1 }}>Update Password</MenuItem>
        <MenuItem onClick={logout} sx={{ m: 1 }}>Logout</MenuItem>
      </MenuPopover>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Update Password</DialogTitle>
        <DialogContent>
          <Grid style={{ marginTop: 20 }}>
            <TextField
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" autoFocus onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button variant="outlined" autoFocus onClick={handleUpdatePassword}>
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
